import { ref, reactive, watch, computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { isEqual } from 'lodash-es';

import { date, format, getDaysBetween } from '@aspect/shared/utils/date.ts';
import { useRoute } from '@aspect/shared/composables/use-route.ts';
import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';
import { useTicketOfficeStore } from '@aspect/ticket-office/stores/use-ticket-office-store.ts';

import type {
    ConsumerSlotRequest,
    SlotData,
    ScheduleData,
    DivisionData,
    SlotStatsData,
} from '@aspect/shared/types/generated';
import type { Ref } from 'vue';
import type { Dayjs } from 'dayjs';

interface ConsumerSlotResponse {
    stats: SlotStatsData;
    slots: SlotData[];
    weeklyLimitReached: boolean;
}

export function useReservationSlots({
    division,
    schedules = ref([]),
    location,
    partySize,
}: {
    division: Ref<DivisionData>,
    schedules?: Ref<ScheduleData[]>,
    location?: Ref<string | null>,
    partySize?: Ref<number | null>,
}) {
    const store = useTicketOfficeStore();
    const { consumerRoute } = useRoute();

    const locationFilter = computed(() => location?.value || null);
    const partySizeFilter = computed(() => partySize?.value || null);


    // SLOT STATS
    const slotStatsLoading = ref(false);
    const slotStats = reactive<SlotStatsData>({
        count: 0,
        minDate: null,
    });


    // SLOTS
    const slotsByDay = ref<Record<string, SlotData[]>>({});
    const slotsLoading = ref(false);
    const datesLoading = ref<string[]>([]);
    const weeklyLimitReached = ref(false);

    async function getInitialSlots() {
        slotStatsLoading.value = true;
        slotStats.count = 0;
        slotStats.minDate = null;

        slotsLoading.value = true;
        slotsByDay.value = {};

        const data = await getSlotsForActiveDay();

        if (!data) {
            return;
        }

        slotStatsLoading.value = false;

        if (data.stats.count > 1) {
            await getSlotsForOtherDays();
        }

        slotsLoading.value = false;
    }

    async function getSlotsForActiveDay() {
        const data = await getSlotsForDay(activeDay.value);

        if (!data) {
            return;
        }

        const minDateIsSet = slotStats.minDate !== null;
        const minDateIsInFuture = date(data.stats.minDate).isAfter(date().endOf('week'));

        slotStats.count = data.stats.count;
        slotStats.minDate = data.stats.minDate;

        if (!minDateIsSet) {
            if (minDateIsInFuture) {
                activeDay.value = date(slotStats.minDate).startOf('day');
            } else {
                activeDay.value = date().startOf('day');
            }
        }

        return data;
    }

    async function getSlotsForOtherDays() {
        const days = getDaysBetween(startDate.value, endDate.value);
        const queries = days
            .filter(currentDate => !currentDate.isSame(activeDay.value, 'day'))
            .map(currentDate => getSlotsForDay(currentDate));

        return Promise.all(queries);
    }

    async function getSlotsForWeek() {
        slotsLoading.value = true;

        const currentStartDate = startDate.value;
        const currentEndDate = endDate.value;

        const days = getDaysBetween(currentStartDate, currentEndDate);
        const queries = days.map(currentDate => getSlotsForDay(currentDate));

        await Promise.all(queries);

        const isSameTimes = currentStartDate === startDate.value && currentEndDate === endDate.value;

        if (isSameTimes) {
            slotsLoading.value = false;
        }
    }

    async function getSlotsForDay(day: Dayjs): Promise<ConsumerSlotResponse | undefined> {
        const formattedDay = day.format('YYYY-MM-DD');

        if (datesLoading.value.includes(formattedDay)) {
            return;
        }

        datesLoading.value.push(formattedDay);

        const form = useForm<ConsumerSlotRequest>({
            date: formattedDay,
            customerMembershipId: store.selectedMembership?.id || null,
            schedules: schedules.value.map(schedule => schedule.id),
            partySize: partySizeFilter.value,
            location: locationFilter.value,
        });

        const response = await useAxiosForm(form).get(consumerRoute('/{division}/slots', {
            division: division.value.id,
        }));

        if (!response) {
            return;
        }

        const updatedSlotsByDay = { ...slotsByDay.value };

        updatedSlotsByDay[formattedDay] = response.data.slots;

        slotsByDay.value = updatedSlotsByDay;
        datesLoading.value = datesLoading.value.filter(d => d !== formattedDay);
        weeklyLimitReached.value = response.data.weeklyLimitReached;

        return response.data;
    }

    function clearSlots(startDate: string, endDate: string) {
        const updatedSlotsByDay = { ...slotsByDay.value };

        Object.keys(updatedSlotsByDay).forEach(day => {
            if (day < startDate || day > endDate) {
                delete updatedSlotsByDay[day];
            }
        });

        slotsByDay.value = updatedSlotsByDay;
    }

    watch([schedules, () => division.value.id], () => {
        getInitialSlots();
    });

    // SELECTED SLOT
    const selectedSlot = ref<SlotData|null>(null);

    const slots = computed(() => {
        return Object.values(slotsByDay.value).flat();
    });

    watch([() => slotStats.count], () => {
        if (slotStats.count === 1 && slots.value.length) {
            selectedSlot.value = slots.value[0];
        }
    });

    watch(slots, () => {
        // Make sure selected slots stil exists.
        const updatedSelectedSlot = slots.value.find(slot => slot.id === selectedSlot.value?.id);

        if (updatedSelectedSlot && !isEqual(selectedSlot.value, updatedSelectedSlot)) {
            selectedSlot.value = updatedSelectedSlot;
        }
    });


    // ACTIVE DAY
    const activeDay = ref(date().startOf('day'));

    const startDate = computed(() => {
        return format(activeDay.value.startOf('week'));
    });
    const endDate = computed(() => {
        return format(activeDay.value.endOf('week'));
    });

    watch(activeDay, () => {
        if (slotStats.count <= 1) {
            return;
        }

        getSlotsForWeek();
        clearSlots(startDate.value, endDate.value);
    });


    return {
        slotsByDay,
        selectedSlot,
        slotsLoading,
        slotStatsLoading,
        slotStats,

        getInitialSlots,
        getSlotsForDay,
        getSlotsForWeek,

        activeDay,
        weeklyLimitReached,
    };
}
