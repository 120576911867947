<template>
    <div
        v-if="isBundle"
        class="col-span-full grid grid-cols-1 gap-3 md:grid-cols-2"
    >
        <ReservationOfferingListItemEntry
            v-for="(subEntry, subIndex) in entry.subEntries"
            :key="subIndex"
            :count="entry.subEntries?.length ?? 0"
            :entry="subEntry"
            :index="subIndex"
            :is-sub-entry="true"
            :number="count > 1 ? `${number}.${subIndex + 1}` : subIndex + 1"
            :can-invite-members="canInviteMembers"
            :can-invite-guests="canInviteGuests"
            :guests-maximum="guestsMaximum"
            :entries="entries"
            :selected-slot-entries="selectedSlotEntries"
            :selected-slot="selectedSlot"
            @update="(value: object) => updateSubEntry(subIndex, value)"
        />
    </div>
    <div v-else class="relative isolate rounded">
        <div
            v-if="!entry.inviteType"
            class="flex h-24 items-stretch justify-center divide-x divide-dashed divide-gray-300 rounded border border-dashed border-gray-300 bg-gray-150 shadow-inner-md"
        >
            <button
                v-if="!inviteGuestsLimited"
                class="flex-1 font-normal text-gray-600 transition hover:bg-black/5"
                @click="updateInviteType('guest')"
            >
                {{ t('Guest') }}
            </button>

            <AspectSelect
                v-if="canInviteMembers"
                v-model:search-term="memberSearch"
                align="center"
                content-class="w-64"
                :options="memberOptions"
                :searchable="true"
                :model-value="selectedMemberOption"
                :external-search="true"
                :show-check-mark="false"
                :external-search-loading="membersLoading"
                search-key="searchKey"
                @update:model-value="onSelectMember"
            >
                <template #trigger>
                    <button class="flex-1 font-normal text-gray-600 transition hover:bg-black/5">
                        {{ t('Member') }}
                    </button>
                </template>
                <template #option="{ option }: { option: MemberOption }">
                    <div class="item-center flex gap-3">
                        <figure class="size-10 shrink-0 rounded-full bg-white p-1.5 shadow-md">
                            <img
                                class="size-full rounded-full object-cover"
                                :src="vapor.asset('/images/default-person.png')"
                                alt="Profile Picture"
                            >
                        </figure>
                        <div>
                            <div class="truncate">
                                {{ option.label }}
                            </div>
                            <div class="flex shrink-0 items-center gap-1.5">
                                <AspectIcon name="sign-hashtag" class="size-3" />
                                <AspectData type="id">
                                    {{ option.subLabel }}
                                </AspectData>
                            </div>
                        </div>
                    </div>
                </template>
            </AspectSelect>
        </div>

        <div v-else class="h-24 rounded bg-white shadow-md">
            <div v-if="selectedMember && entry.inviteType === 'member'" class="item-center flex size-full gap-3">
                <figure class="aspect-square h-full shrink-0 border-r border-gray-200">
                    <img
                        class="size-full rounded-l object-cover"
                        :src="vapor.asset('/images/default-person.png')"
                        alt="Profile Picture"
                    >
                </figure>
                <div class="flex flex-col justify-center">
                    <div class="truncate text-lg">
                        {{ selectedMember.name }}
                    </div>
                    <div v-if="selectedMembership" class="flex items-center gap-1.5">
                        <AspectIcon name="sign-hashtag" class="size-3 shrink-0" />
                        <AspectData type="id">
                            {{ getMemberNumber(selectedMember, selectedMembership) }}
                        </AspectData>
                    </div>
                </div>
            </div>
            <div v-else-if="entry.inviteType === 'guest'" class="grid size-full grid-cols-2 items-center gap-2 px-2 lg:px-4">
                <AspectInputGroup>
                    <AspectLabel :required="store.memberBooking || index === 0">
                        {{ t('First Name') }}
                    </AspectLabel>
                    <AspectInput
                        :model-value="entry.firstName"
                        @update:model-value="(value: string) => emit('update', { firstName: value })"
                    />
                </AspectInputGroup>
                <AspectInputGroup>
                    <AspectLabel :required="store.memberBooking || index === 0">
                        {{ t('Last Name') }}
                    </AspectLabel>
                    <AspectInput
                        :model-value="entry.lastName"
                        @update:model-value="(value: string) => emit('update', { lastName: value })"
                    />
                </AspectInputGroup>
            </div>

            <AspectButtonAttached
                v-if="canClearEntry"
                class="absolute right-0 top-0 rounded-bl rounded-tr border-b border-l p-1.5"
                @click="updateInviteType(null)"
            >
                <XIcon class="size-4" />
            </AspectButtonAttached>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, toRef, watch, onMounted } from 'vue';
    import { cloneDeep } from 'lodash-es';
    import { XIcon } from 'lucide-vue-next';

    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { useLaravelVapor } from '@aspect/shared/composables/use-laravel-vapor.ts';

    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';
    import { useMemberSelect } from '@aspect/shared/composables/use-member-select.ts';
    import { useMemberships } from '@aspect/shared/composables/use-memberships.ts';

    import { useMembers } from '@aspect/shared/composables/use-members.ts';
    import { useTicketOfficeStore } from '@aspect/ticket-office/stores/use-ticket-office-store.ts';

    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectInputGroup from '@aspect/shared/components/aspect-input-group.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectSelect from '@aspect/shared/components/aspect-select.vue';
    import AspectData from '@aspect/shared/components/aspect-data.vue';

    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';

    import type { MemberOption } from '@aspect/shared/composables/use-member-select.ts';
    import type {
        CreateEntryData,
        CustomerCreditData,
        EntryData,
        EntryInviteType,
        ItemRedeemedCreditData,
        MemberData,
        SlotData,
    } from '@aspect/shared/types/generated';

    const props = withDefaults(defineProps<{
        entry: CreateEntryData;
        entries: CreateEntryData[];
        number: string | number;
        index: number;
        count: number;
        canInviteMembers: boolean;
        canInviteGuests: boolean;
        guestsMaximum: number | null;
        selectedSlotEntries: EntryData[];
        selectedSlot: SlotData;
        isSubEntry?: boolean
        weeklyLimitReached?: boolean;
    }>(), {
        isSubEntry: false,
    });

    const emit = defineEmits<{
        update: [value: object];
    }>();

    const pageProps = usePageProps();
    const vapor = useLaravelVapor();
    const store = useTicketOfficeStore();


    // IS BUNDLE
    const isBundle = computed(() => {
        return !!props.entry.subEntries?.length;
    });


    // UPDATE SUB ENTRY
    function updateSubEntry(index: number, value: object) {
        if (!props.entry.subEntries) {
            return;
        }

        const updatedEntries = [...props.entry.subEntries];

        updatedEntries[index] = {
            ...updatedEntries[index],
            ...value,
        };

        emit('update', { subEntries: cloneDeep(updatedEntries) });
    }


    // MEMBERS
    const { getMembers, membersLoading } = useMembers();
    const { getMemberNumber } = useMemberships();

    const {
        memberSearch,
        memberOptions,
        selectedMemberOption,
        onSelectMember,
    } = useMemberSelect(
        getMembers,
        membersLoading,
        toRef(props, 'selectedSlot'),
        store.memberBooking,
        props,
    );

    watch(selectedMemberOption, (value) => {
        if (value) {
            updateInviteType('member', value.member, value.membership?.id);
        }
    });

    const selectedMember = computed(() => {
        return selectedMemberOption.value?.member;
    });

    const selectedMembership = computed(() => {
        if (!selectedMemberOption.value) {
            return;
        }

        // We show the first membership number if no membership is specified
        return selectedMemberOption.value.membership || selectedMemberOption.value.member.memberships[0];
    });


    // AUTO SELECT MEMBER
    function autoSelectMember() {
        if (!pageProps.value.member) {
            return;
        }

        if (pageProps.value.member.id !== props.entry.customerId) {
            return;
        }

        if (!store.selectedMembership && props.selectedSlot.scheduleType !== 'event') {
            return;
        }

        selectedMemberOption.value = {
            member: pageProps.value.member,
            membership: store.selectedMembership,
        };
    }

    onMounted(() => {
        autoSelectMember();
    });


    // CAN CLEAR ENTRY
    const canClearEntry = computed(() => {
        if (!props.canInviteMembers || !pageProps.value.member) {
            return false;
        }

        if (!store.memberBooking) {
            return true;
        }

        const entryIsAuthMember = selectedMember.value?.id === pageProps.value.member.id;

        return !selectedMemberOption.value || !entryIsAuthMember;
    });


    // UPDATE INVITE TYPE
    function updateInviteType(
        inviteType: EntryInviteType | null,
        customer?: MemberData,
        customerMembershipId?: string,
    ) {
        emit('update', {
            inviteType,
            customerId: customer?.id || null,
            customerMembershipId: customerMembershipId || null,
            firstName: customer?.firstName || null,
            lastName: customer?.lastName || null,
            customerEntryCredit: getCustomerCreditForEntry(customer?.credits ?? [], props.entry),
        });
    }


    // INVITE GUESTS LIMITED
    const inviteGuestsLimited = computed(() => {
        if (!props.canInviteGuests) {
            return true;
        }

        if (props.guestsMaximum === null) {
            return false;
        }

        const guestsCount = props.entries.reduce((count, entry) => {
            return entry.inviteType === 'guest' ? count + 1 : count;
        }, 0);

        return guestsCount >= props.guestsMaximum;
    });

    const isLoggedInMember = computed(() => {
        return pageProps.value.member?.id === props.entry.customerId;
    });


    function getCustomerCreditForEntry(customerCredits: CustomerCreditData[], entry: CreateEntryData):ItemRedeemedCreditData | null {
        if (props.weeklyLimitReached && isLoggedInMember.value) {
            return null;
        }

        if (!isLoggedInMember.value && selectedMember.value?.weeklyLimitReached) {
            return null;
        }

        const customerCreditForOffering = customerCredits.filter((customerCredit) => {
            if (!customerCredit.customCredit) {
                return false;
            }

            if (entry.entryableType === 'ticket') {
                return customerCredit.ticketValues?.some((ticket) => ticket.id === entry.entryableId && customerCredit.remainingQuantity >= ticket.creditValue);
            }

            if (entry.entryableType === 'bundle') {
                return customerCredit.bundleValues?.some((bundle) => bundle.id === entry.entryableId && customerCredit.remainingQuantity >= bundle.creditValue);
            }
        })[0] ?? null;

        if (!customerCreditForOffering) {
            return null;
        }

        let creditValue: number | null = null;

        if (entry.entryableType === 'ticket') {
            creditValue = customerCreditForOffering.ticketValues?.find((ticket) => ticket.id === entry.entryableId)?.creditValue ?? null;
        } else if (entry.entryableType === 'bundle') {
            creditValue = customerCreditForOffering.bundleValues?.find((bundle) => bundle.id === entry.entryableId)?.creditValue ?? null;
        }

        if (!creditValue) {
            return null;
        }

        return {
            id: customerCreditForOffering?.id,
            redeemedAmount: creditValue
        };
    }

</script>
