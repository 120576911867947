<template>
    <section
        id="offering-step"
        ref="element"
        class="flex scroll-mt-16 flex-col items-center"
    >
        <div v-if="showSlotStep" class="flex justify-center">
            <hr class="h-16 w-1 border-none bg-gray-400">
        </div>

        <div class="w-full max-w-4xl rounded bg-gray-100 shadow-lg">
            <div class="sticky top-16 z-10 px-2 pb-2 sm:px-4 sm:pb-4">
                <div class="-mt-4 flex items-stretch justify-between rounded bg-white shadow-lg">
                    <div class="flex flex-col gap-1 p-4">
                        <AspectLabel>
                            <template v-if="showEndTime && !endTime">
                                {{ t('Reservation starting at') }}
                            </template>
                            <template v-else>
                                {{ t('Reservation for') }}
                            </template>
                        </AspectLabel>

                        <div class="flex flex-col sm:flex-row sm:items-end sm:gap-2">
                            <time class="text-2xl font-light sm:text-3xl lg:text-5xl">
                                {{ startTime }}
                                <span v-if="showEndTime && endTime">
                                    - {{ endTime }}
                                </span>
                            </time>

                            <time class="text-base sm:text-lg lg:text-xl">
                                {{ t('on') }}
                                <span class="font-normal">{{ format(selectedSlot.dateTime, 'LL') }}</span>
                            </time>
                        </div>
                    </div>

                    <AspectButtonAttached
                        v-if="showSlotStep"
                        class="rounded-r border-l border-gray-200"
                        color="black"
                        @click="emit('change')"
                    >
                        {{ t('Change') }}
                    </AspectButtonAttached>
                </div>
            </div>
            <div class="flex flex-col gap-4 p-2 lg:p-4">
                <ReservationOfferingList
                    v-model:entries="entries"
                    :offerings="offerings"
                    :selected-slot="selectedSlot"
                    :can-invite-members="canInviteMembers"
                    :can-invite-guests="canInviteGuests"
                    :guests-maximum="inviteGuestsMaximum"
                    :weekly-limit-reached="weeklyLimitReached"
                />
            </div>

            <slot />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { ref, computed, onMounted } from 'vue';
    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { date, format, formatTime } from '@aspect/shared/utils/date.ts';

    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';
    import { useTicketOfficeStore } from '@aspect/ticket-office/stores/use-ticket-office-store.ts';

    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';

    import ReservationOfferingList from '@aspect/ticket-office/components/reservation-offering-list.vue';

    import type { CreateEntryData, SlotData } from '@aspect/shared/types/generated';
    import type { Offering } from '@aspect/shared/types/global';

    const props = withDefaults(defineProps<{
        selectedSlot: SlotData;
        offerings: Offering[];
        selectedOfferingSlots: SlotData[];
        showSlotStep?: boolean;
        scroll?: boolean;
        weeklyLimitReached: boolean;
    }>(), {
        showSlotStep: true,
        scroll: true,
    });

    const emit = defineEmits<{
        change: [];
    }>();

    const pageProps = usePageProps();
    const store = useTicketOfficeStore();


    // ENTRIES
    const entries = defineModel<CreateEntryData[]>('entries', { required: true });


    // SCROLL
    const element = ref<HTMLElement>();

    onMounted(() => {
        if (props.scroll) {
            element.value?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    });

    // MEMBERSHIP LIMITATIONS
    const scheduleIsEvent = computed(() => props.selectedSlot.scheduleType === 'event');

    const canInviteMembers = computed(() => {
        if (!pageProps.value.member) {
            return false;
        }

        if (scheduleIsEvent.value) {
            return true;
        }

        return store.selectedMembership?.inviteMembers ?? false;
    });

    const canInviteGuests = computed(() => {
        if (scheduleIsEvent.value) {
            return true;
        }

        return store.selectedMembership?.inviteGuests ?? true;
    });

    const inviteGuestsMaximum = computed(() => {
        if (scheduleIsEvent.value) {
            return null;
        }

        return store.selectedMembership?.inviteGuestsMaximum ?? null;
    });


    // START TIME
    const startTime = computed(() => {
        return formatTime(props.selectedSlot.dateTime);
    });


    // END TIME
    const showEndTime = computed(() => {
        return pageProps.value.division.settings.entryType === 'time';
    });

    const endTime = computed(() => {
        if (!props.selectedOfferingSlots.length) {
            return '';
        }

        const lastSlot = props.selectedOfferingSlots[props.selectedOfferingSlots.length - 1];
        const endTime = date(lastSlot.dateTime).add(lastSlot.duration, 'minute');

        return formatTime(endTime);
    });
</script>
